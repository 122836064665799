import React, { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/navigation';

import { COOKIE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';
import { deleteCookie, setCookie } from '@nm-namshi-frontend/core/utils/helper';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useMarketing from '@nm-namshi-frontend/core/hooks/useMarketing';

import { TDevice, TLocale, TLocaleSEO } from '../types';

type TProps = {
    children: React.ReactNode;
    locale: TLocaleSEO;
    device: TDevice;
    isInstallAppNudgeDismissed?: boolean;
};

type TContext = Omit<TProps, 'children'> &
    TLocale & {
        router: {
            push: (path: string) => void;
            replace: (path: string) => void;
        };
        isMobile: boolean;
        isInstallAppNudgeDismissed?: boolean;
        setIsInstallAppNudgeDismissed: (val: boolean) => void;
        utmParams?: Record<string, string>;
    };

const AppContext = React.createContext<TContext | null>(null);

export const AppContextProvider = ({
    children,
    device,
    isInstallAppNudgeDismissed: isNudgeDismissed,
    locale,
}: TProps) => {
    const nextRouter = useRouter();
    const localeConfig = useMemo(() => getLocaleConfig(locale), [locale]);
    const { utmParams } = useMarketing();

    const [isInstallAppNudgeDismissed, setIsInstallAppNudgeDismissed] = useState(isNudgeDismissed);

    const router = {
        push: (path: string) => {
            nextRouter.push(`/${locale}${path}`);
        },
        replace: (path: string) => {
            nextRouter.replace(`/${locale}${path}`);
        },
    };

    const handleDismissNudge = (val: boolean) => {
        setCookie(COOKIE_ID.IS_INSTALL_APP_NUDGE_DISMISSED, val);
        setIsInstallAppNudgeDismissed(val);
    };

    useEffect(() => {
        // deleting the old unused cookie for the open in app nudge
        deleteCookie(COOKIE_ID.OLD_IS_INSTALL_APP_NUDGE_DISMISSED);
    }, []);

    return (
        <AppContext.Provider
            value={{
                utmParams,
                isInstallAppNudgeDismissed,
                setIsInstallAppNudgeDismissed: handleDismissNudge,
                device,
                locale,
                router,
                isMobile: device === 'mobile',
                ...localeConfig,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => React.useContext(AppContext as React.Context<TContext>);

export default useAppContext;
