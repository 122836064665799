import {
    TCurrency,
    TLanguage,
    TLocale,
    TLocaleAPI,
    TLocaleISO,
    TLocaleSEO,
    TRegionAPI,
    TRegionSEO,
} from '@nm-namshi-frontend/core/types';
import { COOKIE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';

import { REGIONCONFIG } from '../config';
import { setCookie } from './helper';

enum API_COUNTRY_KEYS {
    'saudi' = 'sa',
    'uae' = 'ae',
    'kuwait' = 'kw',
    'qatar' = 'qa',
    'bahrain' = 'bh',
    'oman' = 'om',
}

export enum LANGUAGES {
    'en' = 'en',
    'ar' = 'ar',
}

/**
 * @param  {string} locale?
 * @returns string  HTML direction-specific info depending on the current router locale
 */
export const getThemeDirections = (locale?: string): { isRTL: boolean; left: string; right: string } => {
    const isRTL = locale?.split('-')?.[1] === 'ar';
    const left = isRTL ? 'right' : 'left';
    const right = isRTL ? 'left' : 'right';

    return {
        isRTL,
        left,
        right,
    };
};

/**
 * @param  {string} locale?
 * @returns {string} Language per locale
 */
export const getLocaleLanguage = (locale?: string): TLanguage => {
    // Default case
    if (!locale) return 'en';
    return locale?.split('-')?.[1] as TLanguage;
};

/**
 * @param  {string} locale?
 * @returns string Country ID relevant for address creations/updates
 */
export const getLocaleCountryCode = (locale?: string): TRegionAPI => {
    // Default case
    if (!locale) return API_COUNTRY_KEYS.uae;

    const localeCountry = locale?.split('-')?.[0];
    const countryCode = API_COUNTRY_KEYS[localeCountry as keyof typeof API_COUNTRY_KEYS];

    return countryCode;
};

/**
 * @param  {string} countryCode
 * @returns string region/country API code
 */
export const getSEOCountryCode = (countryCode: string): TRegionSEO => {
    const countryConfigKey = Object.keys(REGIONCONFIG).find(
        (code) => countryCode === REGIONCONFIG[code as TRegionSEO].codes.api,
    );

    if (!countryConfigKey) return 'uae';

    return REGIONCONFIG[countryConfigKey as TRegionSEO].codes.seo;
};

export const getLocaleConfig = (locale?: TLocaleSEO): TLocale => {
    let lang: TLanguage = LANGUAGES.en;
    let region: TRegionSEO = 'uae';

    try {
        if (locale) {
            [region, lang] = locale.split('-') as [TRegionSEO, TLanguage];
        }
    } catch (error: any) {
        // reportError('Error while parsing locale');
    }

    return {
        lang,
        seoRegion: region,
        apiRegion: REGIONCONFIG[region]?.codes.api,
        seoLocale: `${region}-${lang}`,
        apiLocale: `${lang}-${REGIONCONFIG[region]?.codes.api}` as TLocaleAPI,
        isoLocale: `${lang}-${REGIONCONFIG[region]?.codes.iso}` as TLocaleISO,
        isEnglish: lang === 'en',
        isArabic: lang === 'ar',
        isUAE: region === 'uae',
        isSaudi: region === 'saudi',
        isKuwait: region === 'kuwait',
        isQatar: region === 'qatar',
        isBahrain: region === 'bahrain',
        isOman: region === 'oman',
        currencyCode: REGIONCONFIG[region]?.currencies?.[lang],
        currencyCodeEN: REGIONCONFIG[region]?.currencies?.en,
        creditCardTypes: REGIONCONFIG[region]?.creditCardTypes,
        dialingCode: REGIONCONFIG[region]?.dialingCode,
    };
};

/**
 * @param  {string} locale
 * @returns {TCurrencyEN || ''} Currency for locale - always in ISO 4217 format
 */
export const getSEOCurrency = (locale?: string): TCurrency | '' => {
    // Default case
    if (!locale) return '';

    const lang: TLanguage = LANGUAGES.en;
    let region: TRegionSEO = 'uae';
    [region] = locale.split('-') as [TRegionSEO, TLanguage];

    return `${REGIONCONFIG[region]?.currencies[lang]}`;
};

/**
 * @param  {string} Current app locale
 * @param  {LANGUAGES} Language to be updated to
 * @returns {TLocaleISO} Updated app locale
 */
export const getLanguageModifiedLocale = (oldLocale: TLocaleSEO, newLang: LANGUAGES) => {
    const { apiLocale, isoLocale, seoLocale } = getLocaleConfig(oldLocale);

    setCookie(COOKIE_ID.NOON_LOCALE, apiLocale);
    setCookie(COOKIE_ID.OLD_NAMSHI_LOCALE, isoLocale.split('-').join('_'));
    const [region] = seoLocale.split('-');

    return `${region}-${newLang}`;
};

/**
 * @param  {string} Current app locale
 * @returns {string} HTML direction
 */
export const getDirFromLocale = (locale: string | undefined): 'ltr' | 'rtl' =>
    locale?.split('-')?.[1] === 'en' ? 'ltr' : 'rtl';

/**
 * @returns {Array<{apiRegionCode, seoRegionCode}>} Available regions and corresponding data
 */
export const getAllRegionCodes = () =>
    Object.values(REGIONCONFIG).map(({ codes }) => ({
        apiRegionCode: codes.api,
        seoRegionCode: codes.seo,
    }));

/**
 * @returns {boolean} Get if BNPL is active or not in the region via FE config
 */
export const getIsBnplActive = (seoRegion: TRegionSEO): boolean =>
    Boolean(
        REGIONCONFIG[seoRegion].paymentMethods.tabby.isActive || REGIONCONFIG[seoRegion].paymentMethods.tamara.isActive,
    );
