'use client';

import React from 'react';

import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import useWindowDimensions from '@nm-namshi-frontend/core/hooks/useWindowDimensions';
import { SCREEN_SIZES } from '@nm-namshi-frontend/core/constants/uiConstants';

import styles from './Modal.module.scss';
import { isServer } from '../utils/helper';

export type TModalProps = {
    children: React.ReactNode;
    containerClass?: string;
    desktopMaxHeight?: string;
    desktopHeight?: string;
    desktopMaxWidth?: string;
    desktopWidth?: string;
    mobileMaxHeight?: string;
    footer?: React.ReactNode;
    hasScrim?: boolean;
    panelStyle?: string;
    headerStyle?: string;
    id?: string;
    intro?: string | null;
    isContentCovering?: boolean; // Content covers the entire modal
    mobileHeight?: string;
    onBack?: () => void;
    onClick?: () => void;
    onClose?: (arg0: React.SyntheticEvent) => void;
    open: boolean;
    openTransition?: boolean;
    title?: string | React.ReactNode;
    titleStyle?: string;
    showCloseButton?: boolean;
    fullScreen?: boolean;
    contentClassName?: string;
    customHeader?: React.ReactNode;
    showAsSidePanel?: boolean;
    footerStyles?: string;
};

const Modal = ({
    children,
    containerClass,
    contentClassName,
    customHeader,
    desktopHeight,
    desktopMaxHeight,
    desktopMaxWidth,
    desktopWidth,
    footer,
    footerStyles,
    fullScreen = false,
    hasScrim = true,
    headerStyle = '',
    id,
    intro,
    isContentCovering = false,
    mobileHeight,
    mobileMaxHeight,
    onBack,
    onClick,
    onClose,
    open,
    openTransition = true,
    panelStyle = '',
    showAsSidePanel = false,
    showCloseButton = true,
    title,
    titleStyle,
}: TModalProps) => {
    const bodyLock = 'body { overflow: hidden }';
    const { width: viewportWidth } = useWindowDimensions();

    const isMobile = viewportWidth <= SCREEN_SIZES.screenMinSm;
    if (isServer()) return null;

    const fullScreenStyle = {
        ...(isMobile &&
            fullScreen && {
                top: 0,
                bottom: 0,
                position: 'absolute',
            }),
    };
    const BackButton = () => (
        <button type="button" onClick={onBack}>
            <Icon name="arrowBack" size={24} />
        </button>
    );

    const showHeader = title || (showCloseButton && onClose) || customHeader;

    // Renders
    return (
        <>
            {createPortal(
                <>
                    {open && <style>{bodyLock}</style>}
                    <CSSTransition
                        in={open}
                        timeout={{ enter: 200, exit: 200 }}
                        classNames={{
                            enter: styles.modalEnter,
                            enterActive: styles.modalEnterActive,
                            exit: styles.modalExit,
                            exitActive: styles.modalExitActive,
                        }}
                        unmountOnExit
                    >
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                if (onClick) onClick();
                            }}
                            className={clsx(styles.modal, { [styles.sidePanel]: !isMobile && showAsSidePanel })}
                            id={id}
                        >
                            <div className={styles.scrim} onClick={onClose} aria-hidden="true" />
                            <div
                                className={clsx(styles.panel, panelStyle, {
                                    [styles.withTransition]: openTransition,
                                })}
                                style={
                                    {
                                        '--modal-mobile-height': mobileHeight || 'auto',
                                        '--modal-desktop-width': desktopWidth || '50%',
                                        '--modal-desktop-max-width': desktopMaxWidth || '90%',
                                        '--modal-desktop-height': desktopHeight || 'auto',
                                        '--modal-desktop-max-height': desktopMaxHeight || '90%',
                                        '--modal-mobile-max-height': mobileMaxHeight || '100%',
                                        ...fullScreenStyle,
                                    } as React.CSSProperties
                                }
                            >
                                {showHeader &&
                                    (customHeader || (
                                        <header
                                            className={clsx(
                                                styles.header,
                                                !!title && styles.withBorder,
                                                isContentCovering && styles.absolutePositioned,
                                                headerStyle,
                                            )}
                                        >
                                            {onBack ? <BackButton /> : null}
                                            <div>
                                                <div className={styles.titleContent}>
                                                    <h3 className={titleStyle}>{title}</h3>
                                                </div>
                                                {intro && <p className={styles.intro}>{intro}</p>}
                                            </div>
                                            {showCloseButton && onClose && (
                                                <button type="button" onClick={onClose}>
                                                    <Icon name="close" size={25} />
                                                </button>
                                            )}
                                        </header>
                                    ))}
                                <div
                                    className={clsx(
                                        styles.content,
                                        containerClass,
                                        isContentCovering && styles.covering,
                                        contentClassName,
                                    )}
                                >
                                    {children}
                                </div>
                                {footer && <footer className={clsx(styles.footer, footerStyles)}>{footer}</footer>}
                            </div>
                        </div>
                    </CSSTransition>
                </>,
                document.body,
            )}
        </>
    );
};

export default Modal;
