import React from 'react';

import Logo from '@nm-namshi-frontend/core/components/Logo';
import { STORE_SELECTION_CONFIG } from '@nm-namshi-frontend/core/config';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { TLanguage } from '@nm-namshi-frontend/core/types';

import styles from './StoreList.module.scss';
import AlteredLink from '../AlteredLink';

type TProps = {
    setIsStoreClosedModalVisible: (d: boolean) => void;
    setStoreClosedLanguage: (arg0: TLanguage) => void;
    searchParamsString?: string;
};

const StoreSelection = ({ searchParamsString, setIsStoreClosedModalVisible, setStoreClosedLanguage }: TProps) => {
    // Callbacks
    const getUrlWithQueryParams = (urlWithLeadingSlash: string) =>
        `${urlWithLeadingSlash}${searchParamsString ? `?${searchParamsString}` : ''}`;

    // Renderers
    const renderCountries = () =>
        STORE_SELECTION_CONFIG.map(({ iconName, imageUrl, isClosed, title: { ar: titleAr, en: titleEn }, url }) => (
            <div
                key={titleEn}
                className={styles.country}
                onClick={() => (isClosed ? setIsStoreClosedModalVisible(true) : null)}
            >
                {isClosed ? (
                    <>
                        <span>
                            {!!imageUrl && <img alt={titleEn} src={imageUrl} width={40} height={40} />}
                            {!!iconName && <Icon name={iconName} size={40} />}
                        </span>
                        <div className={styles.countryName}>
                            <span onClick={() => setStoreClosedLanguage('en')}>
                                {titleEn}
                                <span className={styles.lang}>English</span>
                            </span>
                            <span className={styles.arItem} onClick={() => setStoreClosedLanguage('ar')}>
                                {titleAr}
                                <span className={styles.lang}>العربية</span>
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        {!!url?.en && (
                            <AlteredLink locale="" href={getUrlWithQueryParams(`/${url.en}/`)}>
                                {!!imageUrl && <img alt={titleEn} src={imageUrl} width={40} height={40} />}
                                {!!iconName && <Icon name={iconName} size={40} />}
                            </AlteredLink>
                        )}
                        <div className={styles.countryName}>
                            {!!url?.en && (
                                <AlteredLink locale="" href={getUrlWithQueryParams(`/${url.en}/`)}>
                                    {titleEn}
                                    <span className={styles.lang}>English</span>
                                </AlteredLink>
                            )}
                            {!!url?.ar && (
                                <AlteredLink
                                    locale=""
                                    className={styles.arItem}
                                    href={getUrlWithQueryParams(`/${url.ar}/`)}
                                >
                                    {titleAr}
                                    <span className={styles.lang}>العربية</span>
                                </AlteredLink>
                            )}
                        </div>
                    </>
                )}
            </div>
        ));

    return (
        <div className={styles.container}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
                src="https://a.namshicdn.com/cms/large/global/20180302/splash_bg.jpg"
                alt="bg_img"
                className={styles.bgImage}
            />
            <section className={styles.content}>
                <div className={styles.header}>
                    <Logo size={60} />
                    <h4 className={styles.title}>Select Your Country</h4>
                    <p className={styles.details}>Please select your shipping destination.</p>
                </div>
                <section className={styles.countryContainer}>{renderCountries()}</section>
            </section>
        </div>
    );
};

export default StoreSelection;
