import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import { TLanguage } from '@nm-namshi-frontend/core/types';

import styles from './StoreClosedModal.module.scss';

type TProps = {
    open: boolean;
    storeClosedLanguage?: TLanguage;
    onClose: () => void;
};

const TEXTS = {
    en: {
        title: 'Store Temporarily Closed',
        body: {
            message1: 'We have temporarily closed this store. Please connect with',
            message2: 'for further info.',
            message3: 'To access your previous orders, please click on',
        },
    },
    ar: {
        title: 'المتجر مغلق مؤقتا',
        body: {
            message1: 'لقد أغلقنا هذا المتجر مؤقتا. يرجى التواصل مع ',
            message2: 'لمزيد من المعلومات',
            message3: 'للوصول إلى طلبياتك السابقة، يرجى الضغط على ',
        },
    },
};

const StoreClosedModal = ({ onClose, open, storeClosedLanguage }: TProps) => {
    // "areTranslationsLoaded" is necessary when no locale is available eg. in landing page country selection
    const { ready: areTranslationsLoaded, t } = useTranslation('common', { useSuspense: false });
    const isArabic = storeClosedLanguage === 'ar';
    const translations = TEXTS[isArabic ? 'ar' : 'en'];

    return (
        <Modal
            id="StoreClosedModal"
            desktopMaxHeight="80%"
            desktopWidth="400px"
            open={open}
            onClose={onClose}
            showCloseButton
            title={areTranslationsLoaded ? t('location-selector.store_temporary_closed') : translations.title}
            titleStyle={styles.title_style}
            headerStyle={isArabic ? styles.header_ar : undefined}
        >
            <div className={styles.container} dir={isArabic ? 'rtl' : 'ltr'}>
                <p>
                    {areTranslationsLoaded ? (
                        <Trans
                            t={t}
                            i18nKey="location-selector.closed_store_1"
                            components={{
                                strong: <strong />,
                            }}
                        />
                    ) : (
                        <>
                            {translations.body.message1} <strong>care@namshi.com</strong> {translations.body.message2}
                        </>
                    )}
                </p>
                <p>
                    {areTranslationsLoaded ? (
                        <>
                            {t('location-selector.closed_store_2')}{' '}
                            <a href="https://my.namshi.com/orders/">
                                <strong>https://my.namshi.com/orders</strong>
                            </a>
                        </>
                    ) : (
                        <>
                            {translations.body.message3}{' '}
                            <a href="https://my.namshi.com/orders/">
                                <strong>https://my.namshi.com/orders</strong>
                            </a>
                        </>
                    )}
                </p>
            </div>
        </Modal>
    );
};

export default StoreClosedModal;
