import React, { useMemo } from 'react';

import Link, { LinkProps } from 'next/link';
import { isString } from 'lodash';

import { getIsPathnameMissingLocale } from '@nm-namshi-frontend/core/utils/url';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import { addTrailingSlash } from '@nm-namshi-frontend/core/utils/helper';

type TProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> &
    LinkProps & {
        ariaLabel?: string;
        children?: React.ReactNode;
        disabled?: boolean;
        id?: string;
        locale: string;
    } & React.RefAttributes<HTMLAnchorElement>;

const AlteredLink = ({
    ariaLabel,
    children,
    disabled,
    href,
    id,
    locale,
    onClick,
    prefetch = false,
    target,
    ...rest
}: TProps) => {
    const { pathWithLocale, refresh } = useAppPathname();

    const showLoading = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (
            e.altKey ||
            e.ctrlKey ||
            e.shiftKey ||
            e.metaKey ||
            target === '_blank' ||
            hrefWithLocale === pathWithLocale
        )
            return;

        showPageLoader();
    };

    const hrefWithLocale = useMemo(() => {
        // To avoid app breaking
        if (!href) return null;
        if (!locale || target === '_blank') return href;

        let pathname;

        // As per next/link , href can either be a string or UrlObject
        if (isString(href)) {
            // Using standard URL objects to compare origins of the current browser baseURI and new URL constructed with href prop
            // If same -> relative href
            // If not -> absolute href
            // Might be quite expensive in case of bunch of links on the UI - commenting it out for now..
            // const isAbsoluteHref = new URL(document.baseURI).origin !== new URL(href, document.baseURI).origin; // Check if an absolute path is being passed in

            // A quicker, yet simpler way - not sure if its safer though ..
            const isAbsoluteHref = href.indexOf('http://') === 0 || href.indexOf('https://') === 0;

            if (isAbsoluteHref) {
                // Further manipulation not required
                return href;
            }

            pathname = href;
        } else if ('pathname' in (href || {})) {
            pathname = href.pathname;
        }

        if (pathname) {
            if (pathname.charAt(0) !== '/') {
                // Append leading slash
                pathname = `/${pathname}`;
            }

            const pathnameIsMissingLocale = getIsPathnameMissingLocale(pathname);

            // As per next/link , href can either be a string or UrlObject
            if (isString(href)) {
                return pathnameIsMissingLocale ? `/${locale}${pathname}` : pathname;
            }
            if ('pathname' in href) {
                const updatedUrlObjectHref = pathnameIsMissingLocale
                    ? { ...href, pathname: `/${locale}${pathname}` }
                    : href;

                return updatedUrlObjectHref;
            }
        }

        // Default behavior
        return href;
    }, [href, locale, target]);

    const correctedHref = isString(hrefWithLocale)
        ? addTrailingSlash(hrefWithLocale)
        : { ...hrefWithLocale, pathname: addTrailingSlash(hrefWithLocale?.pathname || '') };

    // Disabling prefetches in mweb - until we figure out the issue with latency
    return (
        <Link
            aria-label={ariaLabel}
            href={correctedHref}
            locale={locale}
            target={target}
            onClick={(e) => {
                onClick?.(e);

                if (disabled) {
                    e.preventDefault();
                    return;
                }
                if (correctedHref === pathWithLocale) {
                    e.preventDefault();
                    refresh();
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    return;
                }

                showLoading(e);
            }}
            id={id}
            prefetch={prefetch}
            {...rest}
        >
            {children}
        </Link>
    );
};
export default AlteredLink;
