import { Topbar } from 'topbar';

import { colors } from '@nm-namshi-frontend/core/styles/theme';

import { isBrowser } from './helper';

const config = {
    barThickness: 5,
    shadowBlur: 5,
    shadowColor: 'rgba(0, 0, 0, .5)',
    barColors: {
        '0': colors.theme,
        '0.7': colors.theme,
        '1.0': colors.white,
    },
};
let pageLoader: Topbar | undefined;

const getPageLoader = (): Promise<Topbar> => {
    if (pageLoader) Promise.resolve(pageLoader);

    return new Promise<Topbar>((resolve, reject) => {
        if (isBrowser()) {
            import('topbar').then((topbar) => {
                topbar.default.config(config);
                resolve(topbar.default);
            });
        } else {
            reject();
        }
    });
};

export const showPageLoader = () => {
    if (isBrowser()) getPageLoader().then((topbar) => topbar?.show());
};

export const hidePageLoader = () => {
    if (isBrowser()) getPageLoader().then((topbar) => topbar?.hide());
};
