import React, { useMemo } from 'react';

import clsx from 'clsx';

import { TConfigIcons, ICONS, DEFAULT_ICON_SIZE, AR_FLIP_ICONS, ICONS_AR } from '@nm-namshi-frontend/core/theme/icon';
import { TLanguage } from '@nm-namshi-frontend/core/types';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './Icon.module.scss';

// THIS COMPONENT SHOULD ONLY BE USED FOR 24X24 SINGLE COLOR ICONS THAT ARE FOUND HERE:
// https://www.figma.com/file/2PiSMf4cAqDo6aLfgcKxW0/Library?node-id=967%3A1767
// IF AN ICON YOU ADD NEEDS AUTO FLIPPING - IT SHOULD BE ADDED TO AR_FLIP_ICONS ARRAY BELOW

export type TColors =
    | 'offBlack'
    | 'gold'
    | 'orange'
    | 'grey1'
    | 'grey2'
    | 'systemBlue'
    | 'systemGreen'
    | 'systemOrange'
    | 'systemRed'
    | 'red' // this is just for cs so i won't put it in the design system
    | 'white'
    | 'theme'
    | 'theme-d2'
    | 'darkRed'
    | 'purple-l1';
// Taking only the 'loading' prop
interface IProps extends Partial<Pick<HTMLImageElement, 'alt' | 'loading'>> {
    className?: string;
    color?: TColors | string;
    name: TConfigIcons;
    size?: number;
    lang?: TLanguage;
}

const Icon = ({
    alt,
    className = '',
    color = 'offBlack',
    lang = 'en',
    loading = 'eager',
    name,
    size = DEFAULT_ICON_SIZE,
}: IProps) => {
    const shouldARFlip = AR_FLIP_ICONS.indexOf(name) >= 0;

    const iconSource = useMemo(() => (lang === 'ar' ? ICONS_AR[name] : ICONS[name]), [lang, name]);

    return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
            alt={alt || name}
            className={clsx(styles.icon, className, styles[color], '--namshi-icon', shouldARFlip && styles.flipped)}
            color={color}
            height={size}
            src={iconSource}
            width={size}
            loading={loading}
            style={{
                minWidth: size,
            }}
        />
    );
};

export default Icon;
