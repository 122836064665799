'use client';

import { useEffect, useState } from 'react';

import QueryString from 'qs';

import { STANDARD_TRACKING_PARAMS } from '@nm-namshi-frontend/core/config';

const useMarketing = () => {
    const [utmParams, setUtmParams] = useState<Record<string, string>>();

    useEffect(() => {
        initializeUTMParams();
    }, []);

    const initializeUTMParams = () => {
        // Remove the '?'
        const urlQueryParamsString = window.location.search.split('?')[1];

        const urlQueryParamsObject = QueryString.parse(urlQueryParamsString) as Record<string, string>;

        // Take only the required marketing URL params that are maintainig in core config
        const filteredUrlQueryParamsObject = Object.keys(urlQueryParamsObject).reduce((currentObject, key) => {
            if (STANDARD_TRACKING_PARAMS.includes(key) && !currentObject[key]) {
                return { ...currentObject, [key]: urlQueryParamsObject[key] };
            }

            return currentObject;
        }, {} as Record<string, string>);

        setUtmParams(filteredUrlQueryParamsObject);
    };

    return {
        utmParams,
    };
};

export default useMarketing;
