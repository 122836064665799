import { ClientApp, TRegionSEO } from '@nm-namshi-frontend/core/types';
import { REVIEW_ROUTES } from '@nm-namshi-frontend/core/constants/uiConstants';

import { ACCOUNT_NAV_CONFIG, REGIONCONFIG, NAMSHI_ACCOUNT_PAGE_FALLBACK_URL } from '../config';
/* eslint-disable prefer-destructuring */
const { _ENV } = process.env;
const IS_STAGING = _ENV === 'staging';
const IS_BROWSER = typeof window !== 'undefined';

export type TAppSubdomain = 'dev' | 'www' | 'www-alt' | 'account' | 'account-alt' | 'www-alt3';
export type TUrlQueryParam = [string, string];

// Localization config
const seoRegions = Object.keys(REGIONCONFIG);
const defaultSEORegion = seoRegions[0];
const defaultLanguage = REGIONCONFIG[defaultSEORegion as TRegionSEO]?.languages[0];

export const DEFAULT_LOCALE = `${defaultSEORegion}-${defaultLanguage}`;

export const LOCALE_MAP = {
    // Namshi V2
    'en-ae': 'uae-en',
    'ar-ae': 'uae-ar',
    'en-sa': 'saudi-en',
    'ar-sa': 'saudi-ar',
    'en-kw': 'kuwait-en',
    'ar-kw': 'kuwait-ar',
    'en-qa': 'qatar-en',
    'ar-qa': 'qatar-ar',
    'en-bh': 'bahrain-en',
    'ar-bh': 'bahrain-ar',
    'en-om': 'oman-en',
    'ar-om': 'oman-ar',

    // Old Namshi
    en_AE: 'uae-en',
    ar_AE: 'uae-ar',
    en_SA: 'saudi-en',
    ar_SA: 'saudi-ar',
    en_KW: 'kuwait-en',
    ar_KW: 'kuwait-ar',
    en_QA: 'qatar-en',
    ar_QA: 'qatar-ar',
    en_BH: 'bahrain-en',
    ar_BH: 'bahrain-ar',
    en_om: 'oman-en',
    ar_om: 'oman-ar',
};

// Mapping Old Namshi cookie to Namshi v2
export const OLD_LOCALE = {
    en_AE: 'en-ae',
    ar_AE: 'ar-ae',
    en_SA: 'en-sa',
    ar_SA: 'ar-sa',
    en_KW: 'en-kw',
    ar_KW: 'ar-kw',
    en_QA: 'en-qa',
    ar_QA: 'ar-qa',
    en_BH: 'en-bh',
    ar_BH: 'ar-bh',
    en_om: 'en-om',
    ar_om: 'ar-om',
};

export const NOT_SUPPORTED_STORE = ['en_IQ', 'en_US', 'ar_IQ', 'ar_US'];

export const redirectTo = (url: string) => (): void => {
    window.open(url, '_blank');
};

// Gets the suffix for the alt environment eg -alt1 -alt2 -alt3
export const getAltSuffix = (): string => {
    if (IS_BROWSER) {
        const subdomain = window.location.hostname.split('.')[0];
        if (subdomain.includes('-alt')) {
            const altSuffix = subdomain.split('-').pop();
            return `-${altSuffix}`;
        }
    }
    return '';
};

export const getStagingSuffix = (): string => {
    if (IS_BROWSER) {
        const domain = window.location.hostname.split('.')[1];
        if (domain && domain.endsWith('stg')) {
            return `stg`;
        }
    } else if (IS_STAGING) {
        return 'stg';
    }
    return '';
};

export const formattedRedirectUrl = (
    app: TAppSubdomain,
    path?: string | null,
    queryParamsToPersist?: TUrlQueryParam[],
    locale = 'uae-en',
): string => {
    let pathWithQueryParamStr = path || '';

    if (queryParamsToPersist?.length) {
        pathWithQueryParamStr = buildUrlWithQueryParams(pathWithQueryParamStr, queryParamsToPersist);
    }

    const shouldSlashify = () =>
        ['dev', 'www'].reduce((doesIt, startsWith) => doesIt || app.startsWith(startsWith), false);

    return `https://${app}${getAltSuffix()}.namshi${getStagingSuffix()}.com/${locale}/${
        shouldSlashify() ? slashifyUrl(pathWithQueryParamStr) : pathWithQueryParamStr
    }`;
};

export const redirect = (
    path?: string | null,
    app: TAppSubdomain = 'www',
    queryParamsToPersist?: TUrlQueryParam[],
): void => {
    let redirectURL = '';
    redirectURL = formattedRedirectUrl(app, path, queryParamsToPersist);
    window.location.href = redirectURL;
    const [, domain] = redirectURL.split('.');
    if (domain === 'namshi') {
        window.location.href = redirectURL;
    }
};

export const buildUrlWithQueryParams = (
    url: string,
    queryParams: TUrlQueryParam[] = [],
    slashBeforeQueryParam = true,
): string => {
    const keyValueMapper = ([key, val]: TUrlQueryParam) =>
        key && val && `${encodeURIComponent(decodeURIComponent(key))}=${encodeURIComponent(decodeURIComponent(val))}`;

    // check if URL already has any query params
    const queryParamsFromUrl = getUrlQueryParams(url);

    let queryParamsFromCurrentUrl = queryParams;

    // in case, the queryParams cannot be supplied,
    // the url params from window.location.search will be used
    if (!queryParams.length && IS_BROWSER) {
        queryParamsFromCurrentUrl = getUrlQueryParams(window?.location?.search);
        // only get the whitelisted params if any
        // attention nipun
        // queryParamsFromCurrentUrl = queryParamsFromCurrentUrl.filter(([key]) =>
        //     baseConfig?.tracking?.persistQueryParams?.includes(key),
        // );
    }

    // keep only unique query params from queryParamsFromUrl and queryParams
    const uniqueQueryParams = [...queryParamsFromUrl, ...queryParamsFromCurrentUrl]
        // map keys and values to `key=value`
        .map(keyValueMapper)
        // filter out the falsy values
        .filter(Boolean)
        // only keep unique values
        .reduce(
            (keyValueArr, keyValuePair) =>
                keyValueArr.includes(keyValuePair) ? keyValueArr : [...keyValueArr, keyValuePair],
            [] as string[],
        )
        .join('&');

    const [urlWithoutQueryParams = ''] = url.split('?');

    const builtUrl = uniqueQueryParams ? `${urlWithoutQueryParams}?${uniqueQueryParams}` : urlWithoutQueryParams;

    return slashifyUrl(builtUrl, slashBeforeQueryParam);
};

export const slashifyUrl = (url: string, slashBeforeQueryParam = true, slashBeforePath = false): string => {
    let cleanedUrl = url.trim();

    // if there are query params
    let queryParamStr = '';
    if (cleanedUrl.includes('?')) {
        [cleanedUrl, queryParamStr] = cleanedUrl.split('?');
    }

    cleanedUrl = removeTrailingSlash(cleanedUrl);
    if (queryParamStr) {
        if (slashBeforeQueryParam) {
            cleanedUrl = `${cleanedUrl}/?${queryParamStr}`;
        } else {
            cleanedUrl = `${cleanedUrl}?${queryParamStr}`;
        }
    } else {
        cleanedUrl = `${cleanedUrl}/`;
    }

    if (slashBeforePath) {
        if (!cleanedUrl.startsWith('/')) {
            // If the first character is not already '/'
            cleanedUrl = `/${cleanedUrl}`;
        }
    }

    return cleanedUrl;
};

export const getUrlQueryParams = (search = ''): TUrlQueryParam[] => {
    const isValid = !!search && search.length > 1;

    if (!isValid) return [];

    // ignore the '?' in the beginning and everything before it
    const [, ...actualSearchStringArr] = search.split('?');
    const actualSearchString = actualSearchStringArr.join('?');

    // use the URLSearchParams [https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams]
    // util to get the correct search params
    const params = new URLSearchParams(actualSearchString);

    let paramsArr: TUrlQueryParam[] = [];

    params.forEach((value, key) => {
        if (key && value) {
            paramsArr.push([key, value]);
        }
    });

    // sort keys for better structure
    paramsArr = paramsArr.sort(([keyA], [keyB]) => {
        if (keyA > keyB) return 1;
        if (keyA < keyB) return -1;
        return 0;
    });

    return paramsArr;
};

type TOptions = { global?: boolean };
export const removeTrailingSlash = (href = '', options?: TOptions): string => {
    if (options?.global) {
        return href.replace(/^\/+/g, '');
    }
    if (href.endsWith('/')) {
        return href.slice(0, -1);
    }
    return href;
};

/**
 * Returns search params as a string - this is necessary as it is not readily available during SSR
 * @param {object} searchParams
 * @returns {string}
 */
export const getSearchParamsString = (
    searchParams: string | URLSearchParams | string[][] | Record<string, string> | undefined,
) => {
    if (!searchParams) return '';

    const params = new URLSearchParams(searchParams);

    return params.toString();
};

/**
 * Returns an href, as per different url schemes(cloudrun, alt etc.)
 * @param {string} currentClientApp - Name of current app
 * @param {string} targetClientApp - Name of target app
 * @param {string} locale - Locale
 * @param {string} path - Path to navigate to in target app
 * @returns {string}
 */
export const getExternalHref = (
    currentClientApp: ClientApp,
    targetClientApp: ClientApp, // To redirect to bigalog/schmatalog - use targetClientApp= 'www' + deploy to internal URL's via sv prd channel for better results
    locale = 'uae-en',
    path = '',
) => {
    if (typeof window === 'undefined') {
        return '';
    }

    const currentWindowOrigin = window.location.origin;
    let newWindowLocation = '';
    if (
        currentWindowOrigin.includes(`${currentClientApp}.beta.namshi.com`) ||
        currentWindowOrigin.includes(`${currentClientApp}.sv.namshi.com`) ||
        currentWindowOrigin.includes(`${currentClientApp}.beta.namshistg.com`) ||
        currentWindowOrigin.includes(`${currentClientApp}.sv.namshistg.com`)
    ) {
        newWindowLocation = `${currentWindowOrigin.replace(currentClientApp, targetClientApp)}/${locale}${path}`;
    }
    // Test for internal non-cloudrun URL's and classic URL's
    if (
        currentWindowOrigin.includes(`https://www.nm.namshi.com`) ||
        currentWindowOrigin.includes(`https://nm.namshi.com`) ||
        currentWindowOrigin.includes(`https://www-alt.sv.namshi.com`) ||
        currentWindowOrigin.includes(`https://www.sv.namshistg.com`) ||
        currentWindowOrigin.includes(`https://www.namshi.com`) ||
        currentWindowOrigin.includes(`https://www-alt.namshi.com`) ||
        currentWindowOrigin.includes(`https://www-alt3.namshi.com`) ||
        currentWindowOrigin.includes(`https://www.namshistg.com`)
    ) {
        const cleanedWindowOrigin = currentWindowOrigin.replace('www.', '');
        const cleanedWindowOriginSplit = cleanedWindowOrigin.split('https://');

        newWindowLocation = `https://${targetClientApp}.${cleanedWindowOriginSplit[1]}/${locale}${path}`;
    }

    // Test for non-cloudrun URL's and classic URL's of other apps
    if (
        currentWindowOrigin.includes(`https://${currentClientApp}.sv.namshi.com`) ||
        currentWindowOrigin.includes(`https://${currentClientApp}-alt.sv.namshi.com`) ||
        currentWindowOrigin.includes(`https://${currentClientApp}.sv.namshistg.com`) ||
        currentWindowOrigin.includes(`https://${currentClientApp}.namshi.com`) ||
        currentWindowOrigin.includes(`https://${currentClientApp}-alt`) ||
        currentWindowOrigin.includes(`https://${currentClientApp}.namshistg.com`) ||
        currentWindowOrigin.includes(`https://${currentClientApp}.nm.namshi.com`)
    ) {
        newWindowLocation = `${currentWindowOrigin.replace(currentClientApp, targetClientApp)}/${locale}${path}`;
    }

    return newWindowLocation;
};

export const getIsPathnameMissingLocale = (pathname: string) => {
    const allSEORegions = Object.keys(REGIONCONFIG);

    const allSEOLocales = allSEORegions
        .map((seoRegion) =>
            REGIONCONFIG[seoRegion as TRegionSEO].languages.map((language) => `${seoRegion}-${language}`),
        )
        .flat();

    const pathnameIsMissingLocale = allSEOLocales.every(
        (localeSEO) => !pathname.startsWith(`/${localeSEO}/`) && pathname !== `/${localeSEO}`,
    );

    return pathnameIsMissingLocale;
};

export const getIsHomePath = (pathname: string) => {
    const allSEORegions = Object.keys(REGIONCONFIG);

    const allSEOLocales = allSEORegions
        .map((seoRegion) =>
            REGIONCONFIG[seoRegion as TRegionSEO].languages.map((language) => `${seoRegion}-${language}`),
        )
        .flat();

    const pathnameIsHome = allSEOLocales.some((localeSEO) => pathname === `/${localeSEO}`);

    return pathnameIsHome;
};

export const getAccountPageUrl = (code: string, locale: string) => {
    const path = ACCOUNT_NAV_CONFIG.find((e) => e.code === code)?.path[0];
    const accountPageUrl = getExternalHref(ClientApp.SCHMATALOG, ClientApp.ACCOUNT, locale, path);

    if (!accountPageUrl) {
        return `${NAMSHI_ACCOUNT_PAGE_FALLBACK_URL}${locale}${path}`;
    }

    return accountPageUrl;
};

export const getReviewOrderSelectionRoute = (order: string, isFromODP: boolean | null = false) => {
    const baseRoute = REVIEW_ROUTES.REVIEW_ORDER_SELECTION.replace('[order]', order);
    return isFromODP ? `${baseRoute}?isFromODP=${isFromODP}` : baseRoute;
};

export const getReviewFormRoute = (
    order: string,
    itemNr: string,
    reviewCode: string | null = null,
    isFromODP: boolean | null = false,
    isFromReviewSubmittedPage: boolean | null = false,
) => {
    const baseRoute = REVIEW_ROUTES.REVIEW_FORM.replace('[order]', order).replace('[itemNr]', itemNr);
    return reviewCode
        ? `${baseRoute}?reviewCode=${reviewCode}&isFromODP=${isFromODP}&isFromReviewSubmittedPage=${isFromReviewSubmittedPage}`
        : `${baseRoute}?isFromODP=${isFromODP}&isFromReviewSubmittedPage=${isFromReviewSubmittedPage}`;
};

export const getReviewSubmittedRoute = (order: string, itemNr: string, isFromODP: boolean | null = false) => {
    const baseRoute = REVIEW_ROUTES.REVIEW_SUBMITTED.replace('[order]', order).replace('[itemNr]', itemNr);
    return isFromODP ? `${baseRoute}?isFromODP=${isFromODP}` : baseRoute;
};
