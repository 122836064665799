'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { TLocaleSEO } from '../types';

type THookReturn = {
    locale: TLocaleSEO; // @TODO : Ehab - Do we need this to be optional ?
    pathWithoutLocale: string;
    pathWithLocale: string;
    pushWithLocaleAndPath: (arg0: string) => void;
    replaceWithLocaleAndPath: (arg0: string) => void;
    getIsUserOnPage: (arg0: string) => boolean;
    refresh: () => void;
};

/**
 * Custom hook to help get
 * 1. locale(since it is now a part of NextJS app pathname eg. uae-en
 * 2. rest of the path ie. minus the locale ie. the page youre on eg. /bag or /checkout
 * 3. router.push factoring in locale
 */
const useAppPathname = (): THookReturn => {
    const { push, refresh, replace } = useRouter();
    const pathname = usePathname() as string;

    const searchParamsString = useSearchParams().toString();

    let pathWithoutLocale = '/';
    let pathWithLocale = pathname;
    let locale: TLocaleSEO = 'uae-en';

    if (pathname) {
        const pathArray = pathname.split('/').filter((ele) => !!ele); // Filter out empty elements

        if (pathArray.length) {
            // Since locale is currently the root path, it should always be the 0th element in pathArray
            const [localeInPath, ...restOfPath] = pathArray;
            locale = localeInPath as TLocaleSEO; // Typecasting this seems to be the only way to have a fixed return type
            if (restOfPath?.length) {
                // The rest of the path is an array of the elements of the pathname(separated by '/') minus locale
                pathWithoutLocale = `/${restOfPath.join('/')}`;

                if (searchParamsString) {
                    pathWithoutLocale = `${pathWithoutLocale}?${searchParamsString}`;
                }
            } else {
                pathWithoutLocale = '/';
            }
        }
    }

    if (searchParamsString) {
        pathWithLocale = `${pathWithLocale}?${searchParamsString}`;
    }

    const pushWithLocaleAndPath = (path: string) => {
        if (!path) return;

        // Check for leading slash
        if (path.charAt(0) !== '/') {
            push(`/${locale}/${path}`);
        } else {
            push(`/${locale}${path}`);
        }
    };

    const replaceWithLocaleAndPath = (path: string) => {
        if (!path) return;

        // Check for leading slash
        if (path.charAt(0) !== '/') {
            replace(`/${locale}/${path}`);
        } else {
            replace(`/${locale}${path}`);
        }
    };

    const getIsUserOnPage = (pageName: string) => {
        if (!pageName) return false;

        return pathname === `/${locale}/${pageName}/`;
    };

    return {
        locale,
        pathWithoutLocale,
        pathWithLocale,
        pushWithLocaleAndPath,
        replaceWithLocaleAndPath,
        getIsUserOnPage,
        refresh,
    };
};

export default useAppPathname;
