'use client';

import React, { useEffect, useState } from 'react';

import { DEFAULT_DIRECTION } from '@nm-namshi-frontend/core/constants/uiConstants';
import { TLanguage } from '@nm-namshi-frontend/core/types';

import StoreClosedModal from './StoreClosedModal';
import StoreList from './StoreList';

type TProps = {
    searchParamsString?: string;
};
const StoreSelection = ({ searchParamsString }: TProps) => {
    const [isStoreClosedModalVisible, setIsStoreClosedModalVisible] = useState(false);
    const [storeClosedLanguage, setStoreClosedLanguage] = useState<TLanguage>('en');

    useEffect(() => {
        // Set the dir attribute on the html element.
        document.documentElement.setAttribute('dir', DEFAULT_DIRECTION);
    }, []);

    return (
        <>
            <StoreClosedModal
                open={isStoreClosedModalVisible}
                onClose={() => setIsStoreClosedModalVisible(false)}
                storeClosedLanguage={storeClosedLanguage}
            />
            <StoreList
                setIsStoreClosedModalVisible={setIsStoreClosedModalVisible}
                searchParamsString={searchParamsString}
                setStoreClosedLanguage={setStoreClosedLanguage}
            />
        </>
    );
};

export default StoreSelection;
