// ---------------------------------------------------- BREAKPOINTS
// noon have a case of for "large mobiles" at 480px - i'm not sure we need this but we can add in if we do
const xsMin = 480;
const smMin = 768;
const mdMin = 992;
const lgMin = 1200;
const xlMin = 1366;
const xxlMin = 1441;

const bp = {
    xsMin,
    smMin,
    mdMin,
    lgMin,
    xlMin,
    xxlMin,
    xsMax: xsMin - 1,
    smMax: smMin - 1,
    mdMax: mdMin - 1,
    lgMax: lgMin - 1,
} as const;

export const breakpoints = {
    minMobile: `only screen and (min-width: ${bp.xsMin}px)`,
    minTablet: `only screen and (min-width: ${bp.smMin}px)`, // must be 768 = tablet
    minDesktop: `only screen and (min-width: ${bp.mdMin}px)`, // must be 992 = schmall desktop
    minDesktopLG: `only screen and (min-width: ${bp.lgMin}px)`, // must be 1200 - standard desktop
    minDesktopXLG: `only screen and (min-width: ${bp.xlMin}px)`, // must be 1366 - large desktop
    minDesktopXXLG: `only screen and (min-width: ${bp.xxlMin}px)`, // must be 1441 = fancy desktop
    maxMobile: `only screen and (max-width: ${bp.xsMax}px)`,
    maxTablet: `only screen and (max-width: ${bp.smMax}px)`, // up to 767
    maxDesktop: `only screen and (max-width: ${bp.mdMax}px)`, // up to 991
} as const;

// ---------------------------------------------------- COLORS
type TNamshiColors = {
    white: string;
    offBlack: string;

    gold: string;
    coral: string;
    jade: string;

    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    offWhite: string;

    systemBlue: string;
    systemBlueTint: string;
    systemGreen: string;
    systemGreenTint: string;
    systemOrange: string;
    systemOrangeTint: string;
    systemRed: string;
    systemRedTint: string;

    theme: string;
};

// ☠️!!!!!!!!!!!!!!!!!!!!!!!!!!!!! if you change any of these or add any, do so also in ./theme.scss ☠️⚠️
export const colors = {
    white: '#ffffff',
    offBlack: '#282D31',
    gold: '#DCB66C',
    coral: '#ED6A5E',
    jade: '#3BAF7F',
    grey1: '#797D80',
    grey2: '#B0B4B7',
    grey3: '#DBDBDB',
    grey4: '#f7f7fa',
    offWhite: '#F9F7F1',
    systemBlue: '#1688EC',
    systemBlueTint: '#e8f4ff',
    systemGreen: '#38AD3E',
    systemGreenTint: '#e5f5e6',
    systemOrange: '#FBC02D',
    systemOrangeTint: '#fff3d7',
    systemRed: '#F21F24',
    systemRedTint: '#ffddde',
    theme: '#B6ED2F',
} as const;

// ---------------------------------------------------- METRICS
export const metrics = {
    siteMaxWidthDesktop: '1090px',
    siteMaxWidthDesktopLG: '1350px',
} as const;

// ---------------------------------------------------- METRICS
export const typography = {
    en: `'Alexandria', Tahoma, Arial, sans-serif`,
    ar: `'Alexandria', Tahoma, Arial, sans-serif`,
} as const;

// ---------------------------------------------------- BASE STYLES START
export const baseStyles = () => ``;

const theme = {
    colors,
    metrics,
    typography,
} as const;

export type TTheme = typeof theme;
export type TColors = keyof TNamshiColors;

export default theme;
